<template>
  <div class="promo-banner">
    <div class="promo-banner__title banner-title desktop-only">EN CE MOMENT</div>

    <div class="promo-banner__container">
      <img :src="storeConfig.banner_promo_image_url_desktop" alt="promos background image" class="promo-banner__img desktop-only"/>
      <img :src="storeConfig.banner_promo_image_url_mobile" alt="promos background image" class="promo-banner__img smartphone-only"/>
      <div class="promo-banner__text">
        <ContentBlocks :identifiers="['text-banner-promo-desktop']" :title="false" class="promo-banner__cms desktop-only"/>
        <ContentBlocks :identifiers="['text-banner-promo-mobile']" :title="false" class="promo-banner__cms smartphone-only"/>
        <SfButton :link="storeConfig.link_discover" class="promo-banner__link sf-button__secondary">Je découvre</SfButton>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import ContentBlocks from "~/components/ContentBlocks.vue";
import { SfButton } from '@storefront-ui/vue';
import { useConfig } from "~/composables";

export default defineComponent({
  name: "BannerPromos",
  components: {
    ContentBlocks,
    SfButton
  },
  setup() {
    const { config } = useConfig();

    return {
      storeConfig: config
    }
  }
})
</script>
